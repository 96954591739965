nav {
  position: fixed;
  top: 0;
  width: 100%;
  height: 50px;
  z-index: 3;
}

.nav-list {
  list-style-type: none;
  height: 100%;
  display: flex;
  justify-content: right;
  align-items: center;
  position: relative;
  margin-top: 0;
  padding-left: 0;
}

.nav-list-item {
  margin-right: 20px;
  font-size: 20px;
  /* text-transform: uppercase; */
  color: #f1f1f1;
  cursor: pointer;
}

.nav-list-item a {
  text-decoration: none;
  font-weight: bold;
  /* text-shadow:
    0 0 2px #0093bc, 0 0 5px #0093bc, 0 0 30px #6cabbd;
    0 0 40px #9ae8fe; */
  transition: 0.5s;
}

.btn {
  display: none;
  position: absolute;
  right: 10px;
  top: 7px;
  padding: 5px;
  /* color: #000; */
  color: var(--anchor-blue);
  background: none;
  border-radius: 8px;
  font-size: 18px;
  font-weight: bolder;
  transition: 0.5s;
  border: none;
}

@media screen and (max-width: 500px) {
  .nav-list {
    flex-direction: column;
    height: auto;
    background: #000;
  }
  .nav-list-item:nth-child(1) {
    border-top: 1px solid rgba(255, 255, 255, 0.555);
    margin-top: 50px;
  }
  .nav-list-item {
    width: 100%;
    border-top: 1px solid rgba(255, 255, 255, 0.555);
    text-align: center;
    margin-right: 0px;
    padding: 20px 0;
  }
  .btn {
    display: block;
  }
}
